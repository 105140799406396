const getQueryParameters = (url: string): Record<string, string> => {
  const queryString = url?.split('?')[1];
  const params: Record<string, string> = {};

  if (queryString) {
    queryString.split('&').forEach((param) => {
      const [name, value] = param.split('=');
      params[name] = value;
    });
  }

  return params;
};

const doesUrlHaveQueryParameters = (url: string): boolean =>
  /\?/.test(url?.split('/')?.slice(-1)[0]);

const getTranslateRuleUrl = (url: URL): string => {
  let translateRuleUrl = url.pathname;
  if (!translateRuleUrl?.endsWith('/')) {
    translateRuleUrl = translateRuleUrl + '/';
  }

  return translateRuleUrl;
};

export { doesUrlHaveQueryParameters, getQueryParameters, getTranslateRuleUrl };
