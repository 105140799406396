import { Header } from '@dx-ui/osc-header';
import { useTranslation } from 'next-i18next';

import { useLanguage } from '../../hooks';

export const OscMinimalHeader = () => {
  const language = useLanguage();
  const [t] = useTranslation('header');

  return (
    <Header
      brand={{ url: `/${language}/hilton-honors/`, name: t('hiltonHonors'), code: 'HH' }}
      isFluid={true}
      user={undefined}
      userLinks={{
        accountLink: undefined,
        signInLink: undefined,
        signUpLink: undefined,
      }}
      data-testid="newosc"
      onSignOut={() => Promise.resolve()}
      onSignInAttempt={() => Promise.resolve()}
    />
  );
};
