import { RuleEngine } from '@dx-ui/utilities-url-config';

import {
  serverSideFeatureConfigsQuery,
  serverSideFeatureTogglesQuery,
} from '../queries/generated/react-query';

import type { Rule } from '@dx-ui/utilities-url-config';
import type { QueryClient } from '@tanstack/react-query';
import type { FeatureConfigsQuery, FeatureTogglesQuery } from '../types/generated/types';
import { logError } from '@dx-ui/framework-logger';

export const getRouteProtectedFeatureToggles = async (
  client: QueryClient,
  routeFlag: string,
  additionalFlags: string[] = []
) => {
  const toggles = await serverSideFeatureTogglesQuery(client, {
    flags: [routeFlag, ...additionalFlags],
  }).catch((e) => {
    logError('Error on serverSideFeatureTogglesQuery at utils/featureToggles: ', e);
    return { featureToggles: [], routeEnabled: false };
  });
  return {
    routeEnabled: isFeatureEnabled(toggles?.featureToggles, routeFlag),
    featureToggles: toggles?.featureToggles,
  };
};

export const useServerSideFeatureToggles = async (client: QueryClient, flags: string[] = []) => {
  try {
    const toggles = await serverSideFeatureTogglesQuery(client, { flags });
    return toggles?.featureToggles;
  } catch {
    return [];
  }
};

export const getSupportedLanguages = async ({
  client,
  locales,
  path,
}: {
  client: QueryClient;
  locales: string[] | undefined;
  path: string;
}) => {
  const configRules = await serverSideFeatureConfigsQuery(client, {
    names: ['rule-ui-translate-urls'],
  }).catch((e) => {
    logError('Error on serverSideFeatureConfigsQuery at utils/featureToggles: ', e);
    return {
      featureConfigs: [],
    } as FeatureConfigsQuery;
  });

  const ruleEngine = new RuleEngine(configRules?.featureConfigs?.[0] as Rule);
  return {
    supportedLanguages: locales?.filter((language) => ruleEngine?.run?.({ url: path, language })),
    configRules,
  };
};

export const isFeatureEnabled = (
  toggles: FeatureTogglesQuery['featureToggles'],
  toggleName: string
) => {
  try {
    return toggles.find(({ name }) => toggleName === name)?.enabled || false;
  } catch {
    return false;
  }
};
