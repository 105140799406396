import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAuth, getGuestFirstName } from '@dx-ui/framework-auth-provider';
import type { Link } from '@dx-ui/osc-link';
import type { LoginResponse } from '@dx-ui/osc-login';
import type { HeaderProps } from '@dx-ui/osc-header';
import { Header } from '@dx-ui/osc-header';
import type { TFunction } from 'i18next';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';

import { GO_HILTON_GUEST } from '../../utils/constants/go-hilton';
import { hasValidProductCode } from '../../utils/helpers/hasValidProductCode';
import type { SupportedLanguage } from '@dx-ui/framework-i18n';
import { getLoginRedirectUrl } from '../../utils/helpers/urlBuilder';

import type { GuestHHonorsPackage } from '@dx-ui/gql-types';

const getAdditionalLinks = (
  t: TFunction<['header', 'osc-header']>,
  currentPageURL: string | undefined,
  packages: Pick<GuestHHonorsPackage, 'packageName'>[] = []
) => {
  const links: Link[] = [];
  const canShowGoHiltonProgramDetailsLink = hasValidProductCode(
    packages,
    GO_HILTON_GUEST as unknown as string[]
  );
  const canShowHVGMaxProgramDetailsLink = hasValidProductCode(packages, ['HGD']);
  const canShowManageFriendsAndFamilyLink = hasValidProductCode(packages, ['TMH']);

  if (
    (currentPageURL?.includes('go-hilton') || currentPageURL?.includes('hgv-max')) &&
    canShowGoHiltonProgramDetailsLink
  ) {
    links.push({
      isNewWindow: false,
      label: t('programDetails', { context: 'goHilton' }),
      url: `https://help.hilton.com/s/article/What-is-the-Go-Hilton-employee-program`,
    });
  }

  if (currentPageURL?.includes('hgv-max') && canShowHVGMaxProgramDetailsLink) {
    links.push({
      isNewWindow: false,
      label: t('programDetails', { context: 'hgvMax' }),
      url: `https://help.hilton.com/s/article/HGV-Max-rate`,
    });
  }

  if (
    (currentPageURL?.includes('go-hilton') || currentPageURL?.includes('hgv-max')) &&
    canShowManageFriendsAndFamilyLink
  ) {
    links.push({
      isNewWindow: false,
      label: t('manageFAndF'),
      url: `https://tmtp.hilton.com/tmtp/main.html`,
    });
  }
  return links;
};

export const SpecialAccountsFlowHeader = ({
  currentPath,
  forwardPageURI,
  isSMBOwner,
  isSMBEmployee,
}: {
  currentPath?: string;
  forwardPageURI?: string;
  isSMBOwner?: boolean;
  isSMBEmployee?: boolean;
}) => {
  const [user, setUser] = useState<HeaderProps['user']>();

  const { locale, push, pathname, query } = useRouter();
  const [t] = useTranslation(['header', 'osc-header']);
  const { guestInfo, logout, login } = useAuth();

  const packages = guestInfo?.hhonors?.packages;

  const additionalLinks: Link[] = getAdditionalLinks(t, currentPath, packages);
  const brandLink = useMemo(
    () =>
      (isSMBOwner || isSMBEmployee) && pathname.includes('business')
        ? {
            url: `/${locale}/hilton-honors/guest/my-account/`,
            name: t('osc-header:hiltonForTheStay'),
            code: 'WW',
          }
        : { url: `/${locale}/hilton-honors/`, name: t('header:hiltonHonors'), code: 'HH' },
    [isSMBEmployee, isSMBOwner, pathname, locale, t]
  );

  const userLinks = useMemo(
    () =>
      pathname.includes('business')
        ? {
            signInLink: { url: `/${locale}/auth2/guest/login/` },
            signUpLink: {
              url: `/${locale}/hilton-honors/join/?ocode=JHTNW`,
            },
            additionalLinks,
          }
        : {
            accountLink: {
              url: `/${locale}/hilton-honors/guest/my-account/`,
            },
            signInLink: { url: `/${locale}/auth2/guest/login/` },
            signUpLink: {
              url: `/${locale}/hilton-honors/join/?ocode=JHTNW`,
            },
            additionalLinks,
          },
    [additionalLinks, locale, pathname]
  );

  useEffect(() => {
    setUser(
      guestInfo
        ? {
            name: getGuestFirstName({ guestInfo }),
            honorsTier: guestInfo?.hhonors?.summary?.tierName || '',
            honorsPoints: guestInfo?.hhonors?.summary?.totalPoints || 0,
            honorsPointsFmt: guestInfo?.hhonors?.summary?.totalPointsFmt || '',
          }
        : undefined
    );
  }, [guestInfo]);

  const onSignInAttempt: HeaderProps['onSignInAttempt'] = useCallback(
    async (resp: LoginResponse) => {
      if (resp?.data) {
        await login(resp);
        !!forwardPageURI && (await push(forwardPageURI));
      }
    },
    [login, push, forwardPageURI]
  );

  const onSignOut = useCallback(async () => {
    await logout().then(() => {
      if (pathname.includes('hgv-max')) {
        const hgvRedirectUrl = `/${locale ?? 'en'}/hgv-max/`;
        window.location.assign(hgvRedirectUrl);
      }

      if (pathname.includes('go-hilton')) {
        const goHiltonRedirectUrl = `/${locale ?? 'en'}/go-hilton/`;
        window.location.assign(goHiltonRedirectUrl);
      }

      if (pathname.includes('business') && !pathname.includes('hilton-for-business')) {
        const smbLoginRedirectUrl = isSMBEmployee
          ? `/${locale ?? 'en'}/business/member/login/`
          : `/${locale ?? 'en'}/business/login/`;
        window.location.assign(smbLoginRedirectUrl);
      }

      if (/hilton-honors\/guest/.test(pathname)) {
        const redirectUrl = /guest\/points/.test(pathname)
          ? `/${locale ?? 'en'}/hilton-honors/points/`
          : getLoginRedirectUrl(pathname, query, locale as SupportedLanguage);
        window.location.assign(redirectUrl);
      }
    });
  }, [logout, pathname, locale, isSMBEmployee, query]);

  return (
    <Header
      brand={brandLink}
      isFluid={true}
      onSignInAttempt={onSignInAttempt}
      onSignOut={onSignOut}
      user={user}
      userLinks={userLinks}
    />
  );
};
