import { AuthClient } from '@dx-ui/framework-auth-provider';
import { isBrowser } from './helper';
import type { IncomingMessage } from 'http';
import { env } from '@dx-ui/framework-env';

/**
 * 30 min value before user is logged out.
 */
export const IDLE_TIMEOUT = 30 * 60000;

export const auth = (
  req?: Request,
  initialAuthState?: ConstructorParameters<typeof AuthClient>[0]['restore']
) => {
  return new AuthClient({
    appName: env('APP_NAME'),
    authEndpoint: isBrowser
      ? env('DX_AUTH_API_CLIENT_URI')
      : (process.env.DX_AUTH_API_SERVER_URI as string),
    gqlEndpoint: isBrowser
      ? env('DX_GRAPHQL_CLIENT_URI')
      : (process.env.DX_GRAPHQL_SERVER_URI as string),
    appId: env('DX_AUTH_APP_CUSTOMER_ID'),
    request: req as unknown as IncomingMessage,
    restore: initialAuthState,
  });
};

export const callReservationLogin = async ({ confNumber, lastName, arrivalDate }) => {
  if (!isBrowser) throw Error('Reservation login must only be called client-side');

  const options = {
    method: 'POST',
    credentials: 'same-origin' as RequestCredentials,
    headers: {
      'content-language': 'en',
      'content-type': 'application/json',
    },
    body: JSON.stringify({
      app_id: env('DX_AUTH_APP_CUSTOMER_ID'),
      confNumber,
      lastName,
      arrivalDate,
    }),
  };

  let result: Response | undefined;
  try {
    result = await window.fetch(env('DX_AUTH_API_RES_LOGIN'), options);
    if (!result.ok) {
      throw Error(result.statusText);
    } else {
      const data = await result.json();
      return data;
    }
  } catch (error) {
    throw Error(error as string);
  }
};
