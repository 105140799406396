import { useEffect, useMemo, useRef } from 'react';
import { useAuth } from '@dx-ui/framework-auth-provider';
import { useInitConductrics } from '@dx-ui/framework-conductrics';
import { useUserSegments } from '@dx-ui/framework-user-segments';

export const useConductrics = () => {
  const initConductrics = useInitConductrics();
  const { isLoading, isAuthenticated, guestInfo } = useAuth();
  const loaded = useRef(false);
  const userSegments = useUserSegments();
  const knownGuest = useMemo(() => userSegments?.includes('15217574'), [userSegments]);

  useEffect(() => {
    if (!isLoading && !loaded.current) {
      loaded.current = true;
      const { isTeamMember, isOwner, isOwnerHGV, isLongTenure10, isLongTenure20 } =
        guestInfo?.hhonors || {};
      const { tier } = guestInfo?.hhonors?.summary || {};
      const traits = [`login:${isAuthenticated ? 'yes' : 'no'}`];
      if (tier) {
        traits.push(`tier:${tier.toLowerCase()}`);
      }
      if (knownGuest) {
        traits.push('new-existing-members:existing-members');
      } else {
        traits.push('new-existing-members:new-members');
      }
      const teamMember = isTeamMember || isOwner || isOwnerHGV || isLongTenure10 || isLongTenure20;
      traits.push(`teamMember:${teamMember ? 'yes' : 'no'}`);
      initConductrics({ traits });
    }
  }, [initConductrics, isAuthenticated, isLoading, guestInfo, knownGuest]);
};
