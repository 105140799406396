import { useMemo } from 'react';
import { Alert as SharedAlert, AlertDialog, Alerts as SharedAlerts } from '@dx-ui/osc-alert';
import { Link } from '@dx-ui/osc-link';
import Markdown from 'markdown-to-jsx';

import { useLanguage } from '../../hooks';
import { useBrand_AlertQuery } from '../../queries/generated/react-query';

export const Alert = () => {
  const language = useLanguage();
  const { data } = useBrand_AlertQuery({ language });

  const messages = data?.alerts?.messages;

  const getAlerts = useMemo(
    () =>
      messages?.[0] ? (
        <SharedAlerts>
          {messages.map(({ headline, link, type, content }) => (
            <SharedAlert
              key={`${type}-${content}`}
              title={headline || ''}
              status={type === 'error' ? 'emergency' : 'urgent'}
              isTruncated={false}
              className="[&>div_p]:max-w-4/5 [&>div]:flex-wrap"
            >
              {link ? (
                <Link
                  {...link}
                  adaDescription={link?.adaDescription || ''}
                  isNewWindow={link?.isNewWindow || false}
                  label={link?.label || ''}
                  url={link?.url || ''}
                >
                  {link.label}
                </Link>
              ) : (
                !!content && (
                  <AlertDialog label={headline || ''} title={headline || content}>
                    <div className="prose lg:prose-lg">
                      <Markdown>{content}</Markdown>
                    </div>
                  </AlertDialog>
                )
              )}
            </SharedAlert>
          ))}
        </SharedAlerts>
      ) : null,
    [messages]
  );

  return getAlerts;
};
