import { useTranslation } from 'next-i18next';
import { allEnv } from '@dx-ui/framework-env';
import { useRouter } from 'next/router';
import * as React from 'react';

import { getGuestFirstName, useAuth } from '@dx-ui/framework-auth-provider';
import { Header as OSCHeader } from '@dx-ui/osc-header';
import { Speedbump, makeAnchorEventTarget, useSpeedbump } from '@dx-ui/osc-speedbump';

import { getExternalUrl, getLoginRedirectUrl } from '../../utils/helpers/urlBuilder';

import type { HeaderProps } from '@dx-ui/osc-header';

import type { SupportedLanguage } from '@dx-ui/framework-i18n';
import type { LoginResponse } from '@dx-ui/osc-login';
import type { TFunction } from 'i18next';
import { useIsClient } from 'usehooks-ts';
import { useSmb_MemberIdAndStatusQuery } from '../../queries/generated/react-query';
import { useLanguage } from '../../hooks';

type Props = {
  isHomepage?: boolean;
  isFixed?: boolean;
  forwardPageURI?: string | null;
  hasGoHiltonHeader?: boolean;
};

type GetLinkProps = {
  isAuthenticated: boolean;
  isTMHOrOwner: boolean;
  locale: string | undefined;
};

const {
  APP_NAME,
  DX_AUTH_UI,
  OHW_BASE_URL,
  TMTP_MANAGE_FAMILY_AND_FRIENDS_LINK,
  EXTERNAL_TMTP_PROGRAM_DETAILS_URL,
} = allEnv();

export const Header = (props: Props) => {
  const [user, setUser] = React.useState<HeaderProps['user']>();
  const isClient = useIsClient();
  const { locale = 'en', asPath, query } = useRouter();
  const { forwardPageURI, hasGoHiltonHeader } = props;
  const [t] = useTranslation('header');
  const { isAuthenticated, logout, login, guestInfo, guestId } = useAuth();
  const language = useLanguage();
  const { isVisible, onContinue, onHide, onShow } = useSpeedbump(language, APP_NAME);

  const getCreditCardUrl = (locale: string) => {
    switch (locale) {
      case 'ja':
        return 'https://www.americanexpress.com/ja-jp/credit-cards/card-types/cobrand-hilton-mclp/index.html';
      case 'de':
        return 'https://www.hiltonhonorscreditcard.de/';
      default:
        return `/${locale}/hilton-honors/credit-cards/?cid=OH,HH,CobrandHonorsNav,MULTIPR,Tile,Home,SingleLink,i81096`;
    }
  };
  const MEMBER_NAV_LINKS = [
    {
      adaDescription: '',
      isNewWindow: false,
      label: t('locations'),
      onClick: onShow,
      url: getExternalUrl(
        `/${locale}/locations/?cid=OH,HH,HonorsNav,MULTIPR,Tile,Home,SingleLink,i80949`
      ),
    },
    {
      adaDescription: '',
      isNewWindow: false,
      label: t('activity'),
      onClick: onShow,
      url: `/${locale}/hilton-honors/guest/activity/`,
    },
    {
      adaDescription: '',
      isNewWindow: false,
      label: t('creditCards'),
      url: getCreditCardUrl(locale),
      ...(['ja', 'de'].includes(locale) ? {} : { onClick: onShow }),
    },
    {
      adaDescription: '',
      isNewWindow: false,
      label: t('points'),
      onClick: onShow,
      url: `/${locale}/hilton-honors/guest/points/`,
    },
    {
      adaDescription: '',
      isNewWindow: false,
      label: t('profile'),
      onClick: onShow,
      url: `/${locale}/hilton-honors/guest/profile/`,
    },
  ];
  const defaultLinks = [
    {
      adaDescription: '',
      isNewWindow: false,
      label: t('benefits'),
      url: `/${locale}/hilton-honors/member-benefits/`,
    },
    {
      adaDescription: t('points'),
      isNewWindow: false,
      label: t('points'),
      url: `/${locale}/hilton-honors/points/`,
    },
    {
      adaDescription: t('offers'),
      isNewWindow: false,
      label: t('offers'),
      onClick: onShow,
      url: `${OHW_BASE_URL}en/offers/`,
    },
    {
      adaDescription: '',
      isNewWindow: false,
      label: t('creditCards'),
      url: getCreditCardUrl(locale),
      ...(['ja', 'de'].includes(locale) ? {} : { onClick: onShow }),
    },
    {
      adaDescription: t('findStay'),
      isNewWindow: false,
      label: t('findStay'),
      url: getExternalUrl(`/${locale}/book/reservation/find/`),
    },
  ];
  const links = isAuthenticated ? MEMBER_NAV_LINKS : defaultLinks;

  const brandLink =
    isClient && isAuthenticated
      ? `/${locale}/hilton-honors/guest/my-account/`
      : `/${locale}/hilton-honors/`;

  const { data } = useSmb_MemberIdAndStatusQuery(
    { guestId: guestId as number, language },
    { enabled: !!guestId }
  );

  const isSMBMember = data?.programAccounts && data?.programAccounts?.length > 0;

  React.useEffect(() => {
    setUser(
      guestInfo
        ? {
            name: getGuestFirstName({ guestInfo }),
            honorsTier: guestInfo?.hhonors?.summary?.tierName || '',
            honorsPoints: guestInfo?.hhonors?.summary?.totalPoints || 0,
            honorsPointsFmt: guestInfo?.hhonors?.summary?.totalPointsFmt || '',
          }
        : undefined
    );
  }, [guestInfo]);

  const onSignInAttempt = React.useCallback(
    async (resp: LoginResponse) => {
      if (resp?.data) {
        await login(resp);
        if (forwardPageURI) {
          const fakeEventTarget = makeAnchorEventTarget(forwardPageURI);
          if (!onShow(fakeEventTarget)) {
            onContinue();
            if (forwardPageURI) window.location.assign(forwardPageURI);
          }
        }
      }
    },
    [login, forwardPageURI, onShow, onContinue]
  );

  const onSignOut = React.useCallback(async () => {
    await logout().then(() => {
      if (asPath.includes('hgv-max')) {
        const hgvRedirectUrl = `/${locale ?? 'en'}/hgv-max/`;
        window.location.assign(hgvRedirectUrl);
      }

      if (asPath.includes('go-hilton')) {
        const goHiltonRedirectUrl = `/${locale ?? 'en'}/go-hilton/`;
        window.location.assign(goHiltonRedirectUrl);
      }

      if (asPath.includes('business')) {
        const smbLoginRedirectUrl = `/${locale ?? 'en'}/business/login/`;
        window.location.assign(smbLoginRedirectUrl);
      }

      if (/hilton-honors\/guest/.test(asPath)) {
        const redirectUrl = /guest\/points/.test(asPath)
          ? `/${locale ?? 'en'}/hilton-honors/points/`
          : getLoginRedirectUrl(asPath, query, locale as SupportedLanguage);
        window.location.assign(redirectUrl);
      }
    });
  }, [asPath, locale, logout, query]);

  const getGoHiltonLinks = (
    t: TFunction<'header'>,
    { isAuthenticated, isTMHOrOwner, locale }: GetLinkProps
  ) => {
    const hotelSearch = {
      adaDescription: '',
      isNewWindow: false,
      label: t('hotelSearch'),
      url: `/${locale}/go-hilton/find-hotels/`,
    };
    const findStay = {
      adaDescription: '',
      isNewWindow: false,
      label: t('findStay'),
      url: `/${locale}/book/reservation/find/`,
    };
    const findStayReservations = {
      ...findStay,
      url: `/${locale}/hilton-honors/guest/my-account/`,
    };
    const programGuide = {
      adaDescription: '',
      isNewWindow: false,
      label: t('programDetails'),
      url: EXTERNAL_TMTP_PROGRAM_DETAILS_URL,
    };
    const existingReservations = {
      adaDescription: '',
      isNewWindow: false,
      label: t('existingRes'),
      url: `/${locale}/hilton-honors/guest/activity/`,
    };
    const manageFAndF = {
      adaDescription: '',
      isNewWindow: false,
      label: t('manageFAndF'),
      url: TMTP_MANAGE_FAMILY_AND_FRIENDS_LINK,
    };

    if (!isAuthenticated) {
      return [programGuide, findStay];
    }

    if (isAuthenticated && isTMHOrOwner) {
      return [hotelSearch, programGuide, manageFAndF, existingReservations];
    }
    return [hotelSearch, programGuide, findStayReservations];
  };

  const goHiltonLinks = React.useMemo(
    () =>
      getGoHiltonLinks(t, {
        isAuthenticated,
        isTMHOrOwner: Boolean(guestInfo?.hhonors?.isOwner || guestInfo?.hhonors?.isTeamMember),
        locale,
      }),
    [isAuthenticated, guestInfo, locale, t]
  );

  const headerLinks =
    hasGoHiltonHeader && isClient ? goHiltonLinks : isClient ? links : defaultLinks;
  const additionalLink = isSMBMember
    ? [
        {
          label: t('memberBenefits'),
          url: `/${locale}/hilton-honors/member-benefits/`,
        },
        {
          label: 'Manage Hilton for Business',
          url: `${OHW_BASE_URL}${locale}/business/manage/`,
        },
      ]
    : [
        {
          label: t('memberBenefits'),
          url: `/${locale}/hilton-honors/member-benefits/`,
        },
      ];

  return (
    <>
      <OSCHeader
        brand={{
          url: brandLink,
          name: t('hiltonHonors'),
          code: 'HH',
          onClick: onShow,
        }}
        loginOptions={{
          options: {
            showCreatePassword: true,
            forgetInfoLink: {
              url: `${OHW_BASE_URL}${locale}/hilton-honors/login/forgot-information/`,
            },
            secureInfoLink: {
              url: `${OHW_BASE_URL}${locale}/p/global-privacy-statement/`,
            },
            joinLink: {
              url: `${OHW_BASE_URL}${locale}/hilton-honors/join/?ocode=JHTNW`,
            },
          },
        }}
        isFluid={true}
        mainNavLinks={headerLinks}
        onSignOut={onSignOut}
        onSignInAttempt={onSignInAttempt}
        user={user}
        userLinks={{
          accountLink: {
            url: `/${locale}/hilton-honors/guest/my-account/`,
            onClick: onShow,
          },
          signInLink: {
            url: DX_AUTH_UI.replace('/__LANG__/', `/${locale}/`),
          },
          signUpLink: {
            url: `/${locale}/hilton-honors/join/?ocode=JHTNW`,
          },
          additionalLinks: additionalLink,
        }}
      />
      <Speedbump isShowing={isVisible} onClose={onHide} onContinue={onContinue} />
    </>
  );
};
